// Generated by ReScript, PLEASE EDIT WITH CARE

import * as A from "../../../styleguide/components/Link/A.res.js";
import * as H1 from "../../../styleguide/components/Heading/H1.res.js";
import * as ID from "../../../libs/ID.res.js";
import * as Api from "../../../api/Api.res.js";
import * as $$Date from "../../../libs/Date.res.js";
import * as Link from "../../../styleguide/components/Link/Link.res.js";
import * as Hooks from "../../../libs/Hooks.res.js";
import * as Table from "../../../styleguide/components/Table/Table.res.js";
import * as React from "react";
import * as Control from "../../../styleguide/components/Control/Control.res.js";
import * as Popover from "../../../styleguide/components/Popover/Popover.res.js";
import * as $$Promise from "../../../bindings/Promise.res.js";
import * as Spinner from "../../../styleguide/components/Spinner/Spinner.res.js";
import * as Dropdown from "../../../styleguide/forms/Dropdown/Dropdown.res.js";
import * as IconMenu from "../../../styleguide/icons/IconMenu.res.js";
import * as TableRow from "../../../styleguide/components/Table/TableRow.res.js";
import * as Container from "../../../styleguide/components/Container/Container.res.js";
import * as TableBody from "../../../styleguide/components/Table/TableBody.res.js";
import * as TableCell from "../../../styleguide/components/Table/TableCell.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Pagination from "../../../styleguide/components/Pagination/Pagination.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as IconSorting from "../../../styleguide/icons/IconSorting.res.js";
import * as SearchField from "../../../styleguide/forms/SearchField/SearchField.res.js";
import * as TableHeader from "../../../styleguide/components/Table/TableHeader.res.js";
import * as SentryLogger from "../../../loggers/SentryLogger.res.js";
import * as ReactDebounce from "rescript-debounce-react/src/ReactDebounce.res.js";
import * as TableHeaderCell from "../../../styleguide/components/Table/TableHeaderCell.res.js";
import * as DashboardIndexCss from "../../../styleguide/dashboard/components/views/index/DashboardIndexCss.res.js";
import * as DashboardTableCss from "../../../styleguide/dashboard/components/table/DashboardTableCss.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as DashboardFilterCss from "../../../styleguide/dashboard/components/filters/DashboardFilterCss.res.js";
import * as ResourceDashboardNavbar from "../resource-dashboard-navbar/ResourceDashboardNavbar.res.js";
import * as ResourceCategoriesOptions from "./ResourceCategoriesOptions.res.js";

var initialState = {
  status: "FetchingResourceCategories",
  currentPage: 1,
  totalPages: 1,
  sortBy: {
    NAME: "Title",
    VAL: "Asc"
  },
  search: ""
};

function ResourceCategoriesDashboard$ResourceCategoriesDashboard(props) {
  var container = React.useRef(null);
  var searchResourceCategories = ReactDebounce.useDebounced(300, (function (param) {
          param.dispatch({
                TAG: "PerformSearch",
                _0: param.state.search
              });
        }));
  var match = Hooks.useReducer(initialState, (function (state, action) {
          if (typeof action !== "object") {
            switch (action) {
              case "FetchResourceCategories" :
                  return {
                          TAG: "SideEffects",
                          _0: (function (param) {
                              var dispatch = param.dispatch;
                              var state = param.state;
                              var match = state.search;
                              var tmp = match === "" ? undefined : state.search;
                              $$Promise.wait(Api.fetchResourceCategoriesDashboard(state.currentPage, tmp, state.sortBy), (function (x) {
                                      if (x.TAG === "Ok") {
                                        return dispatch({
                                                    TAG: "SucceedResourceCategoriesFetch",
                                                    _0: x._0
                                                  });
                                      }
                                      SentryLogger.error1({
                                            rootModule: "ResourceCategoriesDashboard",
                                            subModulePath: {
                                              hd: "ResourceCategoriesDashboard",
                                              tl: /* [] */0
                                            },
                                            value: "make",
                                            fullPath: "ResourceCategoriesDashboard.ResourceCategoriesDashboard.make"
                                          }, "ResourceCategoriesDashboard::FetchResourceCategories::Error", [
                                            "Error",
                                            x._0
                                          ]);
                                      dispatch("FailResourceCategoriesFetch");
                                    }));
                            })
                        };
              case "FailResourceCategoriesFetch" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: "FailedResourceCategoriesFetch",
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search
                          }
                        };
              case "SortByTitle" :
                  var match = state.status;
                  if (typeof match !== "object" && match === "FetchingResourceCategories") {
                    return "NoUpdate";
                  }
                  var match$1 = state.sortBy;
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: "FetchingResourceCategories",
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: typeof match$1 === "object" && match$1.NAME === "Title" && match$1.VAL !== "Desc" ? ({
                                  NAME: "Title",
                                  VAL: "Desc"
                                }) : ({
                                  NAME: "Title",
                                  VAL: "Asc"
                                }),
                            search: state.search
                          },
                          _1: (function (param) {
                              param.dispatch("FetchResourceCategories");
                            })
                        };
              case "SortByCreatedAt" :
                  var match$2 = state.status;
                  if (typeof match$2 !== "object" && match$2 === "FetchingResourceCategories") {
                    return "NoUpdate";
                  }
                  var match$3 = state.sortBy;
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: "FetchingResourceCategories",
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: typeof match$3 === "object" && match$3.NAME === "CreatedAt" && match$3.VAL !== "Desc" ? ({
                                  NAME: "CreatedAt",
                                  VAL: "Desc"
                                }) : ({
                                  NAME: "CreatedAt",
                                  VAL: "Asc"
                                }),
                            search: state.search
                          },
                          _1: (function (param) {
                              param.dispatch("FetchResourceCategories");
                            })
                        };
              case "SortByUpdatedAt" :
                  var match$4 = state.status;
                  if (typeof match$4 !== "object" && match$4 === "FetchingResourceCategories") {
                    return "NoUpdate";
                  }
                  var match$5 = state.sortBy;
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: "FetchingResourceCategories",
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: typeof match$5 === "object" && match$5.NAME === "UpdatedAt" && match$5.VAL !== "Desc" ? ({
                                  NAME: "UpdatedAt",
                                  VAL: "Desc"
                                }) : ({
                                  NAME: "UpdatedAt",
                                  VAL: "Asc"
                                }),
                            search: state.search
                          },
                          _1: (function (param) {
                              param.dispatch("FetchResourceCategories");
                            })
                        };
              
            }
          } else {
            switch (action.TAG) {
              case "SucceedResourceCategoriesFetch" :
                  var res = action._0;
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: {
                              TAG: "Ready",
                              _0: res.resourceCategories
                            },
                            currentPage: res.currentPage,
                            totalPages: res.totalPages,
                            sortBy: state.sortBy,
                            search: state.search
                          },
                          _1: (function (param) {
                              Belt_Option.map(Caml_option.nullable_to_opt(container.current), (function (prim) {
                                      return prim.scrollTop;
                                    }));
                            })
                        };
              case "UpdatePage" :
                  var nextPage = action._0;
                  var match$6 = state.status;
                  if (typeof match$6 !== "object" && match$6 === "FetchingResourceCategories") {
                    return "NoUpdate";
                  }
                  if (state.currentPage !== nextPage) {
                    return {
                            TAG: "UpdateWithSideEffects",
                            _0: {
                              status: "FetchingResourceCategories",
                              currentPage: nextPage,
                              totalPages: state.totalPages,
                              sortBy: state.sortBy,
                              search: state.search
                            },
                            _1: (function (param) {
                                param.dispatch("FetchResourceCategories");
                              })
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "UpdateSearchInput" :
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: action._0
                          },
                          _1: searchResourceCategories
                        };
              case "PerformSearch" :
                  var match$7 = state.status;
                  if (typeof match$7 !== "object" && match$7 === "FetchingResourceCategories") {
                    return "NoUpdate";
                  }
                  if (action._0 !== state.search) {
                    return "NoUpdate";
                  } else {
                    return {
                            TAG: "UpdateWithSideEffects",
                            _0: {
                              status: "FetchingResourceCategories",
                              currentPage: state.currentPage,
                              totalPages: state.totalPages,
                              sortBy: state.sortBy,
                              search: state.search
                            },
                            _1: (function (param) {
                                param.dispatch("FetchResourceCategories");
                              })
                          };
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  React.useEffect((function () {
          dispatch("FetchResourceCategories");
        }), []);
  var match$1 = state.sortBy;
  var match$2 = state.sortBy;
  var match$3 = state.sortBy;
  var resourceCategories = state.status;
  var tmp;
  tmp = typeof resourceCategories !== "object" ? (
      resourceCategories === "FetchingResourceCategories" ? JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx(Spinner.make, {
                    size: "XL",
                    color: "Teal"
                  }),
              className: DashboardTableCss.spinner
            }) : JsxRuntime.jsx("div", {
              children: "Something went wrong during the search",
              className: DashboardTableCss.fetchError
            })
    ) : Belt_Array.map(resourceCategories._0, (function (resourceCategory) {
            return JsxRuntime.jsxs(TableRow.make, {
                        children: [
                          JsxRuntime.jsx(TableCell.make, {
                                children: JsxRuntime.jsx(Link.make, {
                                      href: "#",
                                      children: resourceCategory.title
                                    })
                              }),
                          JsxRuntime.jsx(TableCell.make, {
                                children: String(resourceCategory.resources)
                              }),
                          JsxRuntime.jsx(TableCell.make, {
                                children: resourceCategory.navigationName
                              }),
                          JsxRuntime.jsx(TableCell.make, {
                                children: $$Date.Naive.format(resourceCategory.createdAt, "MM/dd/yyyy")
                              }),
                          JsxRuntime.jsx(TableCell.make, {
                                children: $$Date.Naive.format(resourceCategory.updatedAt, "MM/dd/yyyy")
                              }),
                          JsxRuntime.jsx(TableCell.make, {
                                children: JsxRuntime.jsxs(Popover.make, {
                                      children: [
                                        JsxRuntime.jsx(Popover.Trigger.make, {
                                              children: JsxRuntime.jsx(Control.make, {
                                                    children: JsxRuntime.jsx(IconMenu.make, {
                                                          size: "MD",
                                                          color: "Teal"
                                                        })
                                                  })
                                            }),
                                        JsxRuntime.jsxs(Dropdown.Body.make, {
                                              position: {
                                                TAG: "Below",
                                                _0: "RightEdge"
                                              },
                                              className: DashboardFilterCss.dropdownBody,
                                              children: [
                                                JsxRuntime.jsx(Control.make, {
                                                      className: DashboardFilterCss.dropdownBodyRow,
                                                      children: JsxRuntime.jsx(A.make, {
                                                            href: "#",
                                                            className: DashboardFilterCss.dropdownBodyLink,
                                                            children: "Edit"
                                                          })
                                                    }),
                                                JsxRuntime.jsx(Control.make, {
                                                      className: DashboardFilterCss.dropdownBodyRow,
                                                      children: JsxRuntime.jsx(A.make, {
                                                            href: "/resources/" + resourceCategory.slug,
                                                            className: DashboardFilterCss.dropdownBodyLink,
                                                            children: "View"
                                                          })
                                                    })
                                              ]
                                            })
                                      ]
                                    })
                              })
                        ]
                      }, ID.toString(resourceCategory.id));
          }));
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(ResourceDashboardNavbar.make, {
                      selectedCategory: "Categories"
                    }),
                JsxRuntime.jsxs(Container.make, {
                      className: DashboardIndexCss.layout,
                      setRef: Caml_option.some(container),
                      children: [
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx(H1.make, {
                                      className: DashboardIndexCss.title,
                                      children: "Resource Categories"
                                    }),
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx(SearchField.make, {
                                              id: "resources-search",
                                              value: state.search,
                                              placeholder: "Search resource categories",
                                              inputClassName: DashboardFilterCss.searchField,
                                              onChange: (function ($$event) {
                                                  dispatch({
                                                        TAG: "UpdateSearchInput",
                                                        _0: $$event.target.value
                                                      });
                                                })
                                            }),
                                        JsxRuntime.jsx(ResourceCategoriesOptions.make, {})
                                      ],
                                      className: DashboardIndexCss.headerControls
                                    })
                              ],
                              className: DashboardIndexCss.header
                            }),
                        JsxRuntime.jsx("section", {
                              children: JsxRuntime.jsxs(Table.make, {
                                    className: DashboardTableCss.table,
                                    children: [
                                      JsxRuntime.jsx(TableHeader.make, {
                                            children: JsxRuntime.jsxs(TableRow.make, {
                                                  children: [
                                                    JsxRuntime.jsx(TableHeaderCell.make, {
                                                          children: JsxRuntime.jsx(Control.make, {
                                                                onClick: (function (param) {
                                                                    dispatch("SortByTitle");
                                                                  }),
                                                                children: JsxRuntime.jsxs("span", {
                                                                      children: [
                                                                        "Resource Category",
                                                                        JsxRuntime.jsx(IconSorting.make, {
                                                                              size: "XXS",
                                                                              direction: typeof match$1 === "object" && match$1.NAME === "Title" ? match$1.VAL : undefined
                                                                            })
                                                                      ],
                                                                      className: DashboardTableCss.controlCell
                                                                    })
                                                              })
                                                        }),
                                                    JsxRuntime.jsx(TableHeaderCell.make, {
                                                          children: "Total Resources"
                                                        }),
                                                    JsxRuntime.jsx(TableHeaderCell.make, {
                                                          children: "Navigation Name"
                                                        }),
                                                    JsxRuntime.jsx(TableHeaderCell.make, {
                                                          children: JsxRuntime.jsx(Control.make, {
                                                                onClick: (function (param) {
                                                                    dispatch("SortByCreatedAt");
                                                                  }),
                                                                children: JsxRuntime.jsxs("span", {
                                                                      children: [
                                                                        "Created At",
                                                                        JsxRuntime.jsx(IconSorting.make, {
                                                                              size: "XXS",
                                                                              direction: typeof match$2 === "object" && match$2.NAME === "CreatedAt" ? match$2.VAL : undefined
                                                                            })
                                                                      ],
                                                                      className: DashboardTableCss.controlCell
                                                                    })
                                                              })
                                                        }),
                                                    JsxRuntime.jsx(TableHeaderCell.make, {
                                                          children: JsxRuntime.jsx(Control.make, {
                                                                onClick: (function (param) {
                                                                    dispatch("SortByUpdatedAt");
                                                                  }),
                                                                children: JsxRuntime.jsxs("span", {
                                                                      children: [
                                                                        "Updated At",
                                                                        JsxRuntime.jsx(IconSorting.make, {
                                                                              size: "XXS",
                                                                              direction: typeof match$3 === "object" && match$3.NAME === "UpdatedAt" ? match$3.VAL : undefined
                                                                            })
                                                                      ],
                                                                      className: DashboardTableCss.controlCell
                                                                    })
                                                              })
                                                        }),
                                                    JsxRuntime.jsx(TableHeaderCell.make, {
                                                          children: ""
                                                        })
                                                  ]
                                                })
                                          }),
                                      JsxRuntime.jsx(TableBody.make, {
                                            children: tmp
                                          })
                                    ]
                                  }),
                              className: DashboardTableCss.tableWrapper
                            }),
                        state.totalPages > 1 ? JsxRuntime.jsx("div", {
                                children: JsxRuntime.jsx(Pagination.make, {
                                      currentPage: state.currentPage,
                                      totalPages: state.totalPages,
                                      onPageClick: (function (x) {
                                          dispatch({
                                                TAG: "UpdatePage",
                                                _0: x
                                              });
                                        })
                                    }),
                                className: DashboardTableCss.pagination
                              }) : null
                      ]
                    })
              ]
            });
}

var ResourceCategoriesDashboard = {
  TableCss: undefined,
  Filters: undefined,
  Css: undefined,
  initialState: initialState,
  make: ResourceCategoriesDashboard$ResourceCategoriesDashboard
};

function ResourceCategoriesDashboard$default(props) {
  return JsxRuntime.jsx(ResourceCategoriesDashboard$ResourceCategoriesDashboard, {});
}

var $$default = ResourceCategoriesDashboard$default;

export {
  ResourceCategoriesDashboard ,
  $$default as default,
}
/* A Not a pure module */
